/* Home.css */

.footnote{
  font-size: 10px;
}

.home {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.hero-content {
  max-width: 600px;
}

.hero-title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
}

.highlight {
  color: #6c63ff;
}

.hero-subtitle {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 40px;
}

.cta-button {
  background-color: #6c63ff;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
}

.cta-button:hover {
  background-color: #5a52cc;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

.skills {
  padding: 50px 20px;
  background-color: #e6f7f8;
}

.skills-content {
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.skills-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.skills-subtitle {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 40px;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.skill-card img {
  max-width: 50px;
  margin-bottom: 20px;
}

.skill-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.skill-card p {
  font-size: 1em;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    margin-bottom: 20px;
  }

  .skills-grid {
    flex-direction: column;
    align-items: center;
  }

  .skill-card {
    width: 80%;
  }
}